import { nextTick, ref } from "vue";
import { useRouter } from "vue-router";

export function useMenu() {
  const menuOpen = ref(false);
  const router = useRouter();

  const toggleMenu = () => {
    menuOpen.value = !menuOpen.value;
  };

  const scrollToContact = (event) => {
    event.preventDefault();

    if (router.currentRoute.value.path !== "/") {
      router.push("/").then(() => {
        nextTick(() => {
          const element = document.getElementById("contact");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
      });
    } else {
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return {
    menuOpen,
    toggleMenu,
    scrollToContact,
  };
}
