<template>
    
    <div class="h-24 w-full bg-[#97B5C3] bg-opacity-10  text-center text-white font-sans font-semibold text-sm">
        <div class="relative w-full h-full flex justify-center items-center">
        <p class="text-[#97B5C3]">© D.P Rénovation</p>
 <div class="absolute right-0 mr-3">
        <picture>
            <img class="w-12" src="../assets/Design_sans_titre-removebg-preview (1).png" alt="logo RGE">
        </picture>
    </div>
        </div>
       
    </div>
</template>