<template>
  <h1
    class="mt-10 font-sans font-bold text-xl text-center uppercase text-yellow-500 md:text-3xl"
  >
    A propos de Nous
  </h1>
  <div class="h-[0.10rem] w-1/3 my-5 bg-black"></div>
  <div
    class="relative overflow-hidden mt-5 p-4 w-10/12 mx-auto text-[#6f8591] shadow-md rounded-lg lg:h-80"
  >
    <picture>
      <img
        class="hidden lg:block absolute inset-0 w-full h-full object-cover opacity-20 lg:object-right-bottom"
        src="../assets/brush-painting-with-yellow-color.jpg"
        alt="Image pinceau"
        loading="lazy"
        width="1000"
        height="667"
      />
    </picture>
    <div class="relative z-10 text-base font-medium md:text-lg">
      <p class="mb-5">
        Nous sommes une jeune entreprise de rénovation intérieure qui se déplace
        sur le secteur de Lille et ses alentours.
      </p>
      <p>
        Nous proposons divers travaux d'intérieurs (placo, peinture, enduit ...)
      </p>

      <h2 class="my-4 font-sans text-xl font-bold text-yellow-500">
        Pourquoi nous choisir ?
      </h2>

      <p>Devis Gratuit</p>
      <p>Travail de Qualité</p>
      <p>Notre Sérieux</p>
      <p>A l'écoute de nos Clients</p>
      <p>Certification RGE</p>
    </div>
  </div>
</template>
