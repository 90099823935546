<template>
  <div class="min-h-screen w-full mx-auto px-4 py-6">
    
    <h1
      class=" border-t-2 border-b-2 p-2 text-center font-sans font-bold text-xl uppercase  text-yellow-500 md:text-3xl"
    >
       Nos Réalisations 
    </h1>
    

    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mt-5">
      <div
        v-for="(realisation, index) in nosRealisations"
        :key="index"
        class="bg-white rounded-lg shadow-md overflow-hidden"
      >
        <img
          :src="realisation.image"
          :alt="realisation.title"
          class="w-full h-48 object-cover lg:h-72"
        />
        <div class="p-2 bg-white">
          <h2 class="text-xl font-semibold italic text-center text-[#97B5C3]">
            {{ realisation.title }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nosRealisations: [
        {
          image: require("@/assets/imgRealisations/img1.png"),
          title: "Peinture Chambre Enfant",
        },
        {
          image: require("@/assets/imgRealisations/img2.png"),
          title: "Peinture Entrée",
        },
        {
          image: require("@/assets/imgRealisations/img3.png"),
          title: "Peinture Etage",
        },
        {
          image: require("@/assets/imgRealisations/img4.png"),
          title: "Peinture Salle à Manger",
        },
        {
          image: require("@/assets/imgRealisations/img5.png"),
          title: "Peinture Cuisine/Sallon",
        },
      ],
    };
  },
};
</script>
