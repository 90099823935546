<template>
  <div class="absolute m-2">
    <div class="cursor-pointer z-50" @click="toggleMenu">
      <div v-if="!menuOpen">
        <div class="w-6 h-1 bg-[#97B5C3] m-1"></div>
        <div class="w-6 h-1 bg-[#97B5C3] m-1"></div>
        <div class="w-6 h-1 bg-[#97B5C3] m-1"></div>
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <router-link to="/">
      <img class="h-16" src="../assets/logoDP.png" alt="logo" />
    </router-link>
  </div>
  <div
    class="fixed inset-0 bg-black transition-opacity"
    :class="{
      'opacity-0 pointer-events-none': !menuOpen,
      'opacity-50  z-[20] ': menuOpen,
    }"
  ></div>
  <div
    :class="[
      'w-48 h-full font-sans font-semibold text-center flex flex-col text-white bg-[#97B5C3] fixed top-0 transition-all duration-200 ease-in-out z-40',
      menuOpen ? 'left-0' : '-left-48',
    ]"
  >
    <div class="w-6 h-6 m-2" @click="toggleMenu">
      <div class="w-6 h-1 bg-white transform rotate-45 translate-y-2"></div>
      <div
        class="w-6 h-1 bg-white transform -rotate-45 translate-y-1"
      ></div>
    </div>
    <div class="flex flex-col gap-5 m-2" @click="toggleMenu">
      <router-link to="/"><div>Accueil</div></router-link>
      <router-link to="/realisations"><div>Nos réalisations</div></router-link>
      <a href="/#contact">Contact</a>
    </div>
  </div>
</template>

<script>
import { useMenu } from "../composables/useMenu.js";

export default {
  setup() {
    const { menuOpen, toggleMenu } = useMenu();

    return {
      menuOpen,
      toggleMenu,
    };
  },
};
</script>
