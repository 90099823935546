<template>
  <div class="relative w-full bg-[#97B5C3] bg-opacity-10 lg:hidden">
    <SlideBurger />
  </div>

  <div class="hidden lg:flex lg:w-full lg:bg-[#97B5C3] lg:bg-opacity-10">
    <MenuDesktop />
  </div>
  <router-view />

  <FooterView />
</template>

<script>
import { useMenu } from "../src/composables/useMenu.js";
import FooterView from "./components/FooterView.vue";
import MenuDesktop from "./components/MenuDesktop.vue";
import SlideBurger from "./components/SlideBurger.vue";

export default {
  components: {
    FooterView,
    SlideBurger,
    MenuDesktop,
  },
  setup() {
    const { menuOpen, toggleMenu, scrollToContact } = useMenu();

    return {
      menuOpen,
      toggleMenu,
      scrollToContact,
    };
  },
};
</script>
