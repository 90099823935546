<template>
  <div class="flex ml-2 w-20 justify-center items-center">
    <router-link to="/">
      <img class="" src="../assets/logoDP.png" alt="logo" />
    </router-link>
  </div>
  <nav
    class="hidden lg:flex lg:justify-center lg:w-full lg:gap-8 lg:items-center lg:h-20"
  >
    <router-link to="/" class="text-[#97B5C3] font-bold">Accueil</router-link>
    <span class="text-yellow-500">|</span>
    <router-link to="/realisations" class="text-[#97B5C3] font-bold"
      >Nos réalisations</router-link
    ><span class="text-yellow-500">|</span>
    <a
      href="/#contact"
      class="text-[#97B5C3] font-bold"
      @click="scrollToContact"
      >Contact</a
    >
  </nav>
</template>

<script>
import { useMenu } from "../composables/useMenu.js";

export default {
  setup() {
    const { menuOpen, toggleMenu, scrollToContact } = useMenu();

    return {
      menuOpen,
      toggleMenu,
      scrollToContact,
    };
  },
};
</script>
