<template>
  <div class="w-full font-sans">
    <div class="relative">
      <div
        class="w-full h-80 z-10 p-2 absolute flex justify-center items-center xl:items-end"
      >
        <p
          class="w-full text-center text-xl italic text-white font-bold md:text-2xl lg:text-3xl"
        >
          Une envie de changement faites appel à notre entreprise
        </p>
      </div>
      <div
        class="absolute z-10 left-1/2 transform -translate-x-1/2 bottom-20 mb-14 md:bottom-20 lg:bottom-24"
      >
        <ButtonGeneral />
      </div>

      <div class="w-full h-[500px] xl:h-[650px]">
        <div class="absolute bg-opacity-30 bg-black h-full w-full"></div>
        <picture>
          <template v-for="source in pictures.sources" :key="source.media">
            <source :srcset="source.srcset" :media="source.media" />
          </template>
          <img
            class="w-full h-full object-cover"
            :src="pictures.image.src"
            :alt="pictures.image.alt"
            :width="pictures.image.width"
            :height="pictures.image.height"
            loading="eager"
            decoding="async"
            fetchpriority="high"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGeneral from "./ButtonGeneral.vue";

export default {
  components: {
    ButtonGeneral,
  },
  data() {
    return {
      pictures: {
        image: {
          src: require("@/assets/imgHome/mobile-home-MSI.jpg"),
          alt: "Image accueil",
          width: "450px",
          height: "700px",
        },
        sources: [
          {
            srcset: require("@/assets/imgHome/desktop-home.jpg"),
            media: "(min-width: 1024px)",
          },
          {
            srcset: require("@/assets/imgHome/tablet-home-MSI.jpg"),
            media: "(min-width: 500px)",
          },
        ],
      },
    };
  },
};
</script>
