<template>
  <h1
    class="mt-10 mb-5 text-center uppercase font-bold text-xl text-yellow-500 md:text-3xl"
  >
    Contact
  </h1>
  <div class="h-[0.10rem] w-1/3 my-5 bg-black"></div>
  <div
    class="my-10 font-sans h-48 flex justify-center relative md:flex md:w-11/12 mx-auto md:h-96"
  >
    <div class="absolute w-full h-full z-0 opacity-20 md:relative md:w-1/2">
      <img
        src="../assets/logoContact.png"
        alt="logo Contact"
        class="w-full h-full object-cover"
      />
    </div>

    <div
      class="w-full text-center my-3 flex flex-col justify-center items-center font-semibold z-10 md:w-1/2 md:bg-slate-50 md:my-0"
    >
      <div class="w-10/12 my-3 text-[#6f8591] ">
        <h2 class="font-bold">Adresse Mail :</h2>
        <h3 class="font-semibold">contact@dp-peinture.fr</h3>
      </div>
      <div class="w-10/12 text-[#6f8591] ">
        <h2 class="font-bold">Téléphone :</h2>
        <h3 class="font-semibold">06.77.77.77.77</h3>
      </div>
    </div>
  </div>
</template>
